import React from 'react';
// import Button from "./Button";
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  function handleClick() {
    navigate('/terms');
  }
  function handleClick_1() {
    var userid = sessionStorage.getItem('userid');
    if (userid === null) {
      navigate('/login');
    } else {
      navigate('/Open_locker');
    }
  }
  const handleClick_2 = () => {
    navigate('/check_locker');
  };
  return (
    <>
      <div className='center'>
        <div className='card-set-up card-height-100'>
          <div className='text-center'>
            <h3>Welcome to Skypark Sentosa Locker</h3>
          </div>
          <div className='my-4'>
            <div className='my-3'>
              <button className='btn-lockernew' onClick={handleClick}>
                Get Locker
              </button>
            </div>
            <div className='my-3'>
              <button className='btn-lockernew' onClick={handleClick_1}>
                My Locker
              </button>
            </div>
            <div className='my-3'>
              <button className='btn-lockernew' onClick={handleClick_2}>
                Local Admin
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
