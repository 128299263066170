// import io from 'socket.io-client';
// const socket = io(`${process.env.REACT_APP_SOCKET}/api/sentosa/socket`);

// socket.on('locker', async (data) => {
//   if (data === 'reload') {
//     console.log('reload');
//     console.log(data);
//   }
// });
import io from 'socket.io-client';

export const sockets = io(`${process.env.REACT_APP_SOCKET}/api/sentosa/socket`);
