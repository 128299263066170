import logo from "./logo.svg";
import "./App.css";
import Terms from "./Components/Terms";
import Welcome from "./Components/Welcome";
import Duration from "./Components/Duration";
import Success from "./Components/Success";
import Detail from "./Components/Detail";
import { Routes, Route, Navigate } from "react-router-dom";
import Notfound from "./Components/Notfound";
import Check from "./Components/Check";
import Open_locker from "./Components/Open_locker";
import LoginPage from "./Components/LoginPage";
import Login from "./Components/Login";
import Local_login from "./Components/Local_login";
import Checkuser from "./Components/Checkuser";
import OpenlockerNew from "./Components/OpenlockerNew";
import TestFile from "./Components/TestFile";
import ProtectedRoute from "./Components/ProtectedRoute";
import ProtectedRouteUser from "./Components/ProtectedRouteUser";
function App() {

  return (
    <div className="App">
      <div className="main-container">
        <Routes>
          {/* <Qrcode/>  
      
      <Terms/>
      <Welcome/>
      <Duration/>
      <Success/>
      <Detail/> */}
         <Route element={<ProtectedRoute/>}>

         <Route exact path="/Openlocker" element={<OpenlockerNew />} />
         <Route exact path="/container" element={<TestFile />} />
         <Route exact path="/success" element={<Success />} />
         <Route exact path="/Checkuser" element={<Checkuser />} />

          </Route>
          <Route element={<ProtectedRouteUser/>}> 
          <Route exact path="/Open_locker" element={<Open_locker />} />

          </Route>
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/login" element={<Login />} />
          
          <Route exact path="/duration" element={<Duration />} />
          <Route exact path="/details" element={<Detail />} />
          <Route exact path="/check" element={<Check />} />
          <Route exact path="*" element={<Notfound />} />
          <Route exact path="/check_locker" element={<Local_login />} />
          
        </Routes>
      </div>
    </div>
  );
}

export default App;
