import React from 'react';
import { Formik, Field, Form } from 'formik';
// import {BiUser} from "react-icons/bi";


function Check() {
  return (
   <>
   <div className='center'>
<div className='card-set-up card-height-350'>
<div className='text-center'>
            <h3 className='bold'>Enter the details</h3>
        </div>
<div className='justify-center'>
   
    <Formik
      initialValues={{
        name:'',
        picked: '',
      }}
      onSubmit={async (values) => {
        
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values }) => (
        <Form>
         
          
          <div role="group" aria-labelledby="my-radio-group">
            <label>
              <Field type="radio" name="picked" value="One" />
              One
            </label>
            <label>
              <Field type="radio" name="picked" value="Two" />
              Two
            </label>
            <div>Picked: {values.picked}</div>
          </div>

          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  </div>

</div>
   </div>
  
   
   </>
  )
}

export default Check