import React, { useEffect, useState , useRef} from 'react';
import slotlogo from '../assests/Group 822.svg';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { sockets } from '../api/socket';

function Duration() {
  // const [price, setprice] = useState(null);
    const renderAfterCalled = useRef(false);

  const [locker, setlocker] = useState(false);
  // const navigate = useNavigate();
  const getlockerdata = async () => {
    var data = await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
      .then((res) => {
        return res.data;
      });
    var checkuser = await data.filter((datanew) => {
      return datanew.user === null;
    });
    if (checkuser.length !== 0) {
      var newdata = checkuser.sort(() => Math.random() - 0.5);
     // console.log(newdata);
      sessionStorage.setItem('locker', newdata[0].locker_number);
      // setprice(newdata[0].price);
      setlocker(true);
    } else {
      setlocker(false);
    }
  };

  const selectslot = () => {
    sessionStorage.setItem('slot', 1);
    sessionStorage.setItem('price', 5);
    window.location.replace('/details');
  };
  // const selectslot1 = () => {
  //   sessionStorage.setItem('slot', 2);
  //   sessionStorage.setItem('price', 8);
  //   window.location.replace('/details');
  // };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      getlockerdata();
      sockets.off('locker').on('locker', async (data) => {
          getlockerdata();
       })
    }
    renderAfterCalled.current = true;
  
  }, []);
  return (
    <>
      <div className='center'>
        <div className='card-set-up card-width-200'>
          <div className='text-center'>
            <h2>Select Locker Duration</h2>
            {locker === true ? (
              <div className='slot-head'>
                <button className='slot' onClick={selectslot}>
                  <img src={slotlogo} alt='slot1'></img>
                  {/* <h3>Slot 1</h3> */}
                  <h5 className='slot-para my-1'>60 mins</h5>
                  <h5 className='slot-para my-1'>$ 5</h5>
                </button>

                {/* <button className="slot" onClick={selectslot1}>
                  <img src={slotlogo} alt="slot1"></img>
               
                  <h5 className="slot-para my-1">120 mins</h5>
                  <h5 className="slot-para my-1">$ 8</h5>
                </button> */}
              </div>
            ) : (
              <h4>No Locker Available</h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Duration;
