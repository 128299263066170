import React, { useEffect } from "react";
import "../assests/css/login.css";
import Button from "./Button";

import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Checkuser = () => {
  const checkuser = async () => {
    var phone = document.getElementById("phone").value;
    if (phone.length === 0) {
      toast.error("Mobile Number is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        phone: phone,
      };
      var loginuser = await axios
        .post(`${process.env.REACT_APP_SERVER}/users/viewbyphone`, data)
        .then((res) => {
          return res.data;
        });
        console.log(loginuser);
      if (loginuser.length !== 0) {
        var datanew = await axios
          .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
          .then((res) => {
            return res.data;
          });
        var mylocker = await datanew.filter((datanew1) => {
          return Number(datanew1.user) === Number(loginuser[0].id);
        });
        if (mylocker.length !== 0) {
          sessionStorage.setItem("checkeduser", loginuser[0].id);
          sessionStorage.setItem("tempuser", loginuser[0].userid);
          window.location.replace("/Openlocker");
        } else {
          toast.info("No Locker Available...", {
            autoClose: 2000,
            transition: Slide,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        toast.error("No user Found...", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  return (
    <>
      <div className="center">
        <div className="card-set-up card-height-100">
          <div className="text-center">
            <h3>Welcome to Skypark Sentosa Locker</h3>
          </div>
          <div className="Login_fields">
            <div className="Login_input">
              <label style={{ color: "black" }}>Customer Mobile Number</label>
              <input placeholder="Enter your Mobile Number" id="phone" />
            </div>
          </div>

          <Button name={"Check User"} handleClick={checkuser} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Checkuser;
