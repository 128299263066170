import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import mqtt from 'mqtt/dist/mqtt';
import { toast, Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Success() {
  const [location, setlocation] = useState(null);
  // const openlocker = () => {
  //   window.location.replace('/Open_locker');
  // };
  const openlockerbtn = async () => {
    var data = await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
      .then((res) => {
        return res.data;
      });
    var locker = sessionStorage.getItem('locker');
    var singlelocker = await data.filter((data) => {
      return data.locker_number === locker;
    });
    console.log(singlelocker[0].locker_number);

    var obj = {
      locker_id: singlelocker[0].locker_number,
      userid: sessionStorage.getItem('userid'),
    };
    var openlocker = await axios
      .post(`${process.env.REACT_APP_SERVER}/locker/lockrelease`, obj)
      .then((res) => {
        return res;
      });
    console.log(openlocker.status);
    // const client = mqtt.connect("ws://broker.emqx.io:8083/mqtt");
    // client.on("connect", function () {
    //   client.subscribe(singlelocker[0].subscribe_topic, function (err) {
    //     if (!err) {
    //       client.publish(
    //         singlelocker[0].publish_topic,
    //         singlelocker[0].message
    //       );
    //     }
    //   });
    // });
    if (openlocker.status === 200) {
      toast.success('Locker Open..', {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.replace('/');
      }, 3000);
    }
  };
  useEffect(() => {
    getlockerdata();
  });
  const getlockerdata = async () => {
    var data = await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
      .then((res) => {
        return res.data;
      });
    var locker = sessionStorage.getItem('locker');
    var singlelocker = await data.filter((data) => {
      return data.locker_number === locker;
    });
    var locationdata = await axios
      .post(`${process.env.REACT_APP_SERVER}/location/view`, {
        id: singlelocker[0].location,
      })
      .then((res) => {
        return res.data;
      });
    setlocation(locationdata[0].name);
  };
  return (
    <>
      <div className='center'>
        <div className='card-set-up card-height-250 space'>
          <div className='success-card text-center'>
            <h2>Your Locker no : </h2>
            <h1>{sessionStorage.getItem('locker')}</h1>
            <h2>Location: {location}</h2>
          </div>

          <div className='text-center marginTop'>
            {/* <h1 className="text-proceed" style={{cursor:"pointer"}} onClick={openlocker}>
              Please proceed to your locker
            </h1> */}
            <label>Please proceed to your locker</label>
            <h4>
              <span>Note :</span> Locker locks automatically <br /> when closed
            </h4>
          </div>
          <div className='text-center'>
            <button className='text-proceed btn-locker' onClick={openlockerbtn}>
              Open locker
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Success;
