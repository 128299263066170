import React, { useEffect, useState } from 'react';
// import Button from './Button';
import { useNavigate } from 'react-router-dom';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import '../assests/css/Open_locker.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { toast, Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StripeCheckout from 'react-stripe-checkout';
import moment from 'moment';
// import io from "socket.io-client";
// import mqtt from 'mqtt/dist/mqtt';

// const socket = io.connect();

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};
const style_1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Open_locker = (props) => {
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // lockers
  useEffect(() => {
    getLockers();
  }, []);
  const [locker_data, setlocker_data] = useState([]);
  const [userData, setuserData] = useState([]);
  const getLockers = async () => {
    var userid = sessionStorage.getItem('userid');
    var data = await axios
      .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
      .then((res) => {
        return res.data;
      });
    var mylocker = await data.filter((datanew) => {
      return datanew.user === userid;
    });
    setlocker_data(mylocker);
    // var data_booked = await axios
    //   .get(`${process.env.REACT_APP_SERVER}/lockerbooking/viewall`)
    //   .then((res) => {
    //     return res.data;
    //   });
    // setbooked_lockers(data_booked);
    var data_user = await axios
      .post(`${process.env.REACT_APP_SERVER}/users/view`, { id: userid })
      .then((res) => {
        return res.data;
      });
    setuserData(data_user);
    // setTimeout(() => {
    //   joinRoom();
    // }, 10000);
  };
  // const [room, setRoom] = useState('1');

  // Messages States
  // const [message, setMessage] = useState('hii');
  // const [messageReceived, setMessageReceived] = useState('');

  // const joinRoom = () => {
  //   if (room !== "") {
  //     socket.emit("join_room", room);
  //     getLockers();
  //   }
  //   sendMessage();
  // };
  // const sendMessage = () => {
  //   socket.emit("send_message", { message, room });
  // };
  // useEffect(() => {
  //   socket.on("receive_message", (data) => {
  //     setMessageReceived(data.message);
  //   });
  // }, [socket]);

  // open locker handler
  const [locker_id, setlocker_id] = useState('');
  // const [lockerstatus, setlockerstatus] = useState('');

  const open_handler = (e) => {
    setuser(e.target.value);
    // var singleLocker = locker_data.filter((data) => {
    //   return Number(e.target.id) === data.id;
    // });
    // setlockerstatus(singleLocker[0].status);
    setlocker_id(e.target.id);
    if (e.target.value.length !== 0) {
      handleOpen_1();
    } else {
      toast.info("It's Empty", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  // check password - booked lockers
  const [user, setuser] = useState([]);
  const [overduedata, setoverduedata] = useState([]);
  const [overdueprice, setoverdueprice] = useState(0);
  // const [booked_lockers, setbooked_lockers] = useState([]);
  const [open_1, setOpen_1] = React.useState(false);
  //  const [closelocker, setcloselocker] = React.useState(false);
  // const [oldlocker, setoldlocker] = React.useState(null);
  const handleOpen_1 = () => setOpen_1(true);
  const handleClose_1 = () => setOpen_1(false);

  // const check_password_handler = async () => {
  //   var passcode = document.getElementById('locker_password').value;
  //   var singleLocker = locker_data.filter((data) => {
  //     return Number(locker_id) === data.id;
  //   });
  //   if (singleLocker.length !== 0) {
  //     var userDetails = userData.filter((data) => {
  //       return Number(data.id) === Number(user);
  //     });

  //     var check_overdue = await axios
  //       .post(`${process.env.REACT_APP_SERVER}/lockerbooking/Checklocker`, {
  //         userid: Number(user),
  //       })
  //       .then((res) => {
  //         return res.data;
  //       });
  //     if (userDetails[0].password === passcode) {
  //       if (check_overdue.length === 0) {
  //         var change_status = await axios
  //           .post(`${process.env.REACT_APP_SERVER}/locker/update`, {
  //             id: singleLocker[0].id,
  //             status: 'lock',
  //           })
  //           .then((res) => {
  //             return res.data;
  //           });
  //         if (change_status === 'Updated Successfully') {
  //           toast.success('Locker Unlocked', {
  //             autoClose: 2000,
  //             transition: Slide,
  //           });
  //           setTimeout(() => {
  //             navigation('/');
  //           }, 1000);
  //         }
  //       } else {
  //         setoverduedata(check_overdue);
  //         setoverdueprice(check_overdue[0].price);
  //         handleOpen();
  //       }
  //     } else {
  //       toast.error('Wrong Passcode', {
  //         autoClose: 2000,
  //         transition: Slide,
  //       });
  //     }
  //   }
  // };
  const [product] = useState({
    name: 'Skypark Sentosa - Locker Booking',
    price: overdueprice,
    description: 'This is a Skypark Sentosa - Locker Booking',
  });

  async function handleToken(token, addresses) {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/checkout`,
      {
        token,
        product,
      }
    );
    //(response.status === 200
    if (response.data?.status !== 'failure') {
      var oldtime = moment(overduedata[0].totime, 'HH:mm');
      var newdata = {
        totime: moment(oldtime)
          .add(overduedata[0].hours + 1, 'hours')
          .format('HH:mm'),
        id: overduedata[0].id,
      };
      var cretebooking = await axios
        .post(`${process.env.REACT_APP_SERVER}/lockerbooking/update`, newdata)
        .then((res) => {
          return res.data;
        });
      if (cretebooking === 'Registration successful') {
        toast.success('Payment Updated successfully..', {
          autoClose: 2000,
          transition: Slide,
        });
      }
      setTimeout(() => {
        navigation('/');
      }, 2000);
    } else {
      toast.error(response.data?.error1, {
        autoClose: 2000,
        transition: Slide,
      });
    }
  }
  const completebtn = async () => {
    var passcode = document.getElementById('locker_password').value;
    var singleLocker = locker_data.filter((data) => {
      return Number(locker_id) === data.id;
    });
    if (singleLocker.length !== 0) {
      var userDetails = userData.filter((data) => {
        return Number(data.id) === Number(user);
      });

      var check_overdue = await axios
        .post(`${process.env.REACT_APP_SERVER}/lockerbooking/Checklocker`, {
          userid: Number(user),
        })
        .then((res) => {
          return res.data;
        });
      if (userDetails[0].password === passcode) {
        if (check_overdue.length === 0) {
          var change_status = await axios
            .post(`${process.env.REACT_APP_SERVER}/locker/lockerupdate`, {
              id: singleLocker[0].id,
              status: 'lock',
              user: null,
              userid: sessionStorage.getItem('userid'),
              lockeropentype: 'user',
            })
            .then((res) => {
              return res.data;
            });
          if (change_status === 'Updated Successfully') {
            await axios
              .post(`${process.env.REACT_APP_SERVER}/locker/lockrelease`, {
                locker_id: singleLocker[0].id,
                userid: sessionStorage.getItem('userid'),
              })
              .then((res) => {
                toast.success(res?.data?.message, {
                  autoClose: 2000,
                  transition: Slide,
                });
                return res;
              });

            setTimeout(() => {
              navigation('/');
            }, 1000);
          }
        } else {
          setoverduedata(check_overdue);
          setoverdueprice(check_overdue[0].price);
          handleOpen();
          //  setcloselocker(true);
        }
      } else {
        toast.error('Wrong Passcode', {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const unlockbtn = async (props) => {
    var passcode = document.getElementById('locker_password').value;
    var singleLocker = locker_data.filter((data) => {
      return Number(locker_id) === data.id;
    });
    if (singleLocker.length !== 0) {
      var userDetails = userData.filter((data) => {
        return Number(data.id) === Number(user);
      });

      var check_overdue = await axios
        .post(`${process.env.REACT_APP_SERVER}/lockerbooking/Checklocker`, {
          userid: Number(user),
        })
        .then((res) => {
          return res.data;
        });
      if (userDetails[0].password === passcode) {
        if (check_overdue.length === 0) {
          // var data = {
          //   id: singleLocker[0].id,
          //   publish_topic: singleLocker[0].publish_topic,
          //   subscribe_topic: singleLocker[0].subscribe_topic,
          //   key: singleLocker[0].message,
          // };
          var obj = {
            locker_id: singleLocker[0].id,
            userid: sessionStorage.getItem('userid'),
          };
          console.log(obj);
          await axios
            .post(`${process.env.REACT_APP_SERVER}/locker/lockrelease`, obj)
            .then((res) => {
              toast.success(res?.data?.message, {
                autoClose: 2000,
                transition: Slide,
              });
              // return res;
            });

          // const client = mqtt.connect("ws://broker.emqx.io:8083/mqtt");
          // client.on("connect", function () {
          //   client.subscribe(singleLocker[0].subscribe_topic, function (err) {
          //     if (!err) {
          //       client.publish(
          //         singleLocker[0].publish_topic,
          //         singleLocker[0].message
          //       );
          //     }
          //   });
          // });

          setOpen_1(false);
          getLockers();
          // var change_status = await axios
          //   .post(`${process.env.REACT_APP_SERVER}/locker/unlock`, data)
          //   .then((res) => {
          //     return res.data;
          //   });
          // if (change_status === true) {
          //   toast.success("Locker Unlocked", {
          //     autoClose: 2000,
          //     transition: Slide,
          //   });
          //   getLockers();
          //
          // }
        } else {
          setoverduedata(check_overdue);
          setoverdueprice(check_overdue[0].price);
          handleOpen();
        }
      } else {
        toast.error('Wrong Passcode', {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  return (
    <div>
      {' '}
      <div className='center'>
        <div className='card-set-up BigCard'>
          <div className='text-center'>
            <h3>Lockers</h3>
          </div>
          <div className='All_lockers'>
            {locker_data.length !== 0 ? (
              locker_data.map((data, index) => {
                return (
                  <div className='Locker' key={index}>
                    <LockPersonIcon className='Locker_icon' />
                    <h4 className='Locker_name'>
                      Locker - {data.locker_number}
                    </h4>
                    <span className='Locker_name'>
                      <b>{data.status}</b>
                    </span>
                    {/* {data.status === 'lock' ? (
                      
                    ) : null} */}
                    <button
                      id={data.id}
                      style={{ marginTop: '10px' }}
                      value={data.user}
                      onClick={open_handler}
                    >
                      Open
                    </button>
                  </div>
                );
              })
            ) : (
              <h4>No Locker Available</h4>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='Modal_head'>
            <div>
              {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Hello Santhosh Sivan
          </Typography> */}
              <p className='Exceed_time'>You have exceed your time</p>
            </div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <hr />
          {overduedata.length !== 0 ? (
            <div>
              <h4>Booking Details</h4>
              <p>
                Booking time :
                <span className='Due_pay'>
                  {overduedata[0].fromtime} - {overduedata[0].totime}
                </span>{' '}
              </p>
              <p>
                Overdue time :{' '}
                <span className='Due_pay'>
                  {overduedata[0].hours} hour, {overduedata[0].minutes} minutes
                </span>{' '}
              </p>
              <p>
                Overdue Amount:{' '}
                <span className='Due_pay'>{overduedata[0].price} /-</span>{' '}
              </p>
              <StripeCheckout
                stripeKey='pk_live_51LTLF0JDcsjJGlFu9Pkf8CVVttvwXKPcLVxvP7rrxVDmyCdRaQxxrEkjBSXUZF1nBgZ1gcXmpjKSdhJkGTytA8AI00Ptt9nNVP'
                token={handleToken}
                amount={product.price * 100}
                name='Sample Book'
                billingAddress
                className='payment'
                shippingAddress
                currency='SGD'
              />
            </div>
          ) : null}
        </Box>
      </Modal>
      <ToastContainer />
      <Modal
        open={open_1}
        onClose={handleClose_1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style_1} className='Enter_password'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Enter Your Passcode
          </Typography>
          <input id='locker_password' className='Password_input' />
          <div className='Card_actions'>
            {/* {lockerstatus === 'lock' ? (
              <button onClick={unlockbtn}>Unlock</button>
            ) : (
              // <button onClick={check_password_handler}>Lock</button>
            )} */}
            <button onClick={unlockbtn}>Unlock</button>
            <button onClick={completebtn}>End Locker</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Open_locker;
