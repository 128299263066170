import React, { useEffect } from "react";
import "../assests/css/login.css";
import Button from "./Button";

import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Local_login = () => {
  const checkuser = async () => {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    if (email.length === 0) {
      toast.error("Email is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password.length === 0) {
      toast.error("Password is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        email: email,
        password: password,
      };
      var loginuser = await axios
        .post(`${process.env.REACT_APP_SERVER}/localadmin/login`, data)
        .then((res) => {
          return res.data;
        }).catch((e)=>{
          console.log(e);
          toast.error(e.response.data, {
            autoClose: 2000,
            transition: Slide,
          });
        })
        console.log(loginuser);
      if (loginuser[0].role === "Local Admin") {
          sessionStorage.setItem("local_userid", loginuser[0].id);
          sessionStorage.setItem("local_useremail", loginuser[0].email);
          window.location.reload("/container")
      } else {
        toast.error("No user Found...", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  useEffect(() => {
    var userid = sessionStorage.getItem("local_userid");
    console.log(userid);
    if (userid !== null && userid !== undefined && userid !== '') {
      console.log('ddddddddddddddddddddddd');
      window.location.replace("/container");
    }
    // else{
    //   alert('bbbbbbbbbbbbbbbb')
    // }
  }, []);

  return (
    <>
      <div className="center">
        <div className="card-set-up card-height-100">
          <div className="text-center">
            <h3>Welcome to Skypark Sentosa Locker</h3>
          </div>
          <div className="Login_fields">
            <div className="Login_input">
              <label>Email</label>
              <input placeholder="Enter your Email" id="email" />
            </div>
            <div className="Login_input">
              <label>Password</label>
              <input placeholder="Enter your Password" id="password" />
            </div>
          </div>

          <Button name={"Login"} handleClick={checkuser} />
        </div>
      </div>
      <ToastContainer />
    </>
    // <div className="center">
    //   <div className="card-set-up card-height-100">

    //     <p onClick={register_handler}>Don't have Account ? Sign up.</p>
    //     <button onClick={LoginHandler} className="sign-btn-text">
    //       Sing in
    //     </button>
    //     <ToastContainer />
    //   </div>
    // </div>
  );
};

export default Local_login;
