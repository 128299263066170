import React, { useState } from 'react';
import './Card.css';
import { useNavigate } from 'react-router-dom';
import { toast, Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Terms() {
  const [checkbox, setcheckbox] = useState(false);
  const [bottom, setBottom] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    if (checkbox === true) {
      navigate('/duration');
    } else {
      toast.error('Please Accept the terms & conditions', {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  const getcheckbox = () => {
    setcheckbox(!checkbox);
  };
  // const handleScroll = (e) => {
  //   console.log(
  //     e.target.scrollHeight,
  //     e.target.scrollTop,
  //     e.target.clientHeight
  //     //  e.target.scrollHeight - e.target.scrollTop
  //   );
  //   if (window.innerWidth <= 800) {
  //     // alert(e.target.scrollHeight)
  //     // alert(e.target.scrollTop)
  //     // alert(e.target.clientHeight)

  //     const bottom =
  //       e.target.scrollHeight - Math.trunc(e.target.scrollTop) - 1 ===
  //       e.target.clientHeight;
  //     console.log(bottom);
  //     setBottom(bottom);
  //     return true;
  //   } else {
  //     const bottom =
  //       e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //     console.log(bottom);
  //     setBottom(bottom);
  //   }
  // };

  const myFunction = () => {
    let divObj = document.querySelector('.terms-conditions');
    let clientScrollMinValue = divObj.scrollTop - 5;
    let clientScrollMaxValue = divObj.scrollTop + 5;
    let targetValue = divObj.scrollHeight - divObj.clientHeight;
    if (
      targetValue >= clientScrollMinValue &&
      targetValue <= clientScrollMaxValue
    ) {
      setBottom(true);
    } else {
      setBottom(false);
    }
  };
  return (
    <>
      <div className='center'>
        <div className='card-set-up card-width-300 '>
          <div className='text-center'>
            <h3 className='my-2'>Welcome To Skypark Sentosa Locker</h3>
            {/* <h5 className="my-2">Terms & Conditions</h5> */}
          </div>
          <div className='terms-conditions overflow-auto' onScroll={myFunction}>
            {/* <p className="my-2">
              If users abuse your website or mobile app in any way, you can
              terminate their account.
              <br />
              Your "Termination" clause can inform users that their accounts
              would be terminated
              <br />
              if they abuse your service.
            </p>
            <p className="my-2">
              If users abuse your website or mobile app in any way, you can
              terminate their account. Your "Termination" clause can inform
              users that their accounts would be terminated if they abuse your
              service.
            </p>
            <p className="my-2">
              If users abuse your website or mobile app in any way, you can
              terminate their account.
            </p> */}
            <>
              <h4>
                <b>Smart Locker Terms & Conditions</b>
              </h4>
              The following conditions of sale <b>(“Conditions of Sale”)</b>{' '}
              will apply to and bind the purchaser of the smart locker facility.
              <h5 className='mx-5 my-2'>
                <b>
                  NOTICE TO USER: PLEASE READ THESE CONDITIONS OF SALE CAREFULLY
                  BEFORE SELECTING OR PROCEEDING WITH YOUR PURCHASE.
                  BY PROCEEDING WITH YOUR PURCHASE, USING ANY OF THE SERVICES
                  AND/OR FACILITIES, YOU ACKNOWLEDGE THAT YOU HAVE READ,
                  AND UNDERSTAND AND AGREE TO BE BOUND BY, THESE CONDITIONS OF
                  SALE.
                </b>
              </h5>
              <p>
                YOU CONFIRM THAT YOU ARE AT LEAST 18 YEARS OF AGE. IF YOU ARE
                MAKING A PURCHASE ON BEHALF OF ANOTHER PERSON, YOU AGREE THAT
                YOU ARE DULY AUTHORISED AS THEIR AGENT AND THAT EACH PARTY
                AGREES TO BE BOUND BY THESE CONDITIONS OF SALE. IF YOU ARE
                MAKING A PURCHASE ON BEHALF OF A PARTICIPANT UNDER THE AGE OF 18{' '}
                <b>(“MINOR”)</b>, YOU AGREE THAT YOU ARE MAKING THE PURCHASE
                WITH FULL AUTHORITY AS, OR AS AGENT ON BEHALF OF, THE PARENT OR
                LEGAL GUARDIAN OF THE MINOR, AND AGREE TO BE BOUND BY THESE
                CONDITIONS OF SALE ON BEHALF OF THE MINOR, OR HIS/HER PARENT OR
                LEGAL GUARDIAN (AS THE CASE MAY BE).
              </p>
              {/* <h5 className="mx-5 my-2">IMPORTANT NOTICE – PLEASE READ – THESE  CONDITIONS OF SALE AFFECT YOUR LEGAL RIGHTS
EXCLUSION OF CST’s LIABILITY – WAIVER OF RIGHTS - INDEMNITY
Cerebrum Smart Technologies Pte. Ltd (CST)., as the supplier of the Smart Locker System, sells the locker space at our Client’s premises (“VENUE”) subject to the following conditions in these Conditions of Sale:
</h5> */}
              <h5 className='mx-5 my-1'>
                IMPORTANT NOTICE – PLEASE READ – THESE CONDITIONS OF SALE AFFECT
                YOUR LEGAL RIGHTS
              </h5>
              <h5 className='mx-5 my-1'>
                1. EXCLUSION OF CST’s LIABILITY – WAIVER OF RIGHTS - INDEMNITY{' '}
              </h5>
              <p>
                Cerebrum Smart Technologies Pte. Ltd (CST)., as the supplier of
                the Smart Locker System, sells the locker space at our Client’s
                premises (“VENUE”) subject to the following conditions in these
                Conditions of Sale:
              </p>
              <h5 className='mx-5 my-1'>1.1 REFUND POLICY: </h5>CST operates on
              a strict NO REFUND policy. The Guest agrees that all payments for
              the Smart Locker service are non refundable.
              <h5 className='mx-5 my-1'>
                1.2.       WAIVER, RELEASE AND DISCHARGE: 
              </h5>
              To the fullest extent permitted by law, CST., its employees,
              directors and agents, and all other persons or entities acting in
              a capacity on behalf thereof shall not be liable to any Guest, and
              the Guest hereby waives any and all claims, suits, demands arising
              out of, and the right to sue CST for and releases and discharges
              CST from, any liability for any personal injury or death in any
              way whatsoever caused by or relating to the Guest’s use of the
              Smart Locker System, save and to the extent found to be directly
              caused by CST’s negligence. To the fullest extent permitted by
              law, the Guest agrees to waive, and releases and discharges CST
              from, any and all claims, suits or demands arising from any
              property damage or other loss against CST, including but not
              limited to any and all negligence, faulty equipment, or the
              activities of others.
              <h5 className='mx-5 my-1'>1.3.      INDEMNITY: </h5>To the fullest
              extent permitted by law, the Participant agrees to indemnify,
              defend and hold harmless CST against all and any losses, claims,
              proceedings, damages, liabilities(whether joint or several), costs
              and expenses (including reasonable legal fees), whether (i) as a
              result of the Guest’s own negligence, omission, act, conduct or
              behavior otherwise whilst using the CST Facilities, or being
              otherwise on the CLIENT’s Venue, and/or (ii) arising in connection
              with or as a result of the Guest making a claim against CST
              despite any waiver, release and discharge the Guest may have given
              (whether under these Conditions of Sale, the Waiver and Consent or
              otherwise).
              <br />
              <b className='mx-5 my-1'>1.4.</b>       Guests must use the Smart
              Lockers in a responsible manner, and with respect for their own
              safety and the safety of others. Participants may be liable to
              other individuals for their injuries, if such injuries arise from
              irresponsible, deliberate or reckless behaviour. Participants must
              pay for any damage caused to any part of the Smart Lockers Fa as a
              result of irresponsible, deliberate or reckless behaviour.
              <br />
              <b className='mx-5 my-1'>1.5.</b>       The Guest must comply with
              all signs, notices, directions, instructions and CST reserves the
              right (in its absolute discretion) to suspend or cancel any
              Guest’s access to the Smart Locker Facility, without refund or
              compensation, for non-compliance with these Conditions of Sale,
              signs, notices, directions, instructions or requests, or for any
              unsafe, reckless or careless conduct, to ensure the safety,
              security or order at the Venue, or if CST considers that the
              circumstances so require.
              <h5 className='mx-5 my-1'>
                1.6.     IMAGES, PERSONAL INFORMATION AND PRIVACY POLICY: 
              </h5>
              The Guest acknowledges that CST may operate closed circuit
              television or cameras at the Venue and consents to images and
              videos being taken for security of Guests and minors for whom they
              are responsible. Such images and videos will be the exclusive
              property of CST and the Guest agrees to the free publication
              and/or use in any form or media whatsoever of such images and
              videos, without payment or compensation of any kind.
              <h5 className='mx-5 my-1'>2. SMART LOCKER RENTAL PROCEDURE </h5>
              <br />
              <b className='mx-5 my-1'>2.1.</b>       You may offer to purchase
              the smart locker rental described on the Webapp for the price
              specified on the Webapp.
              <br />
              <b className='mx-5 my-1'>2.2.</b>       Your order must contain
              your name, phone number, e-mail address, credit card details and
              any other ordering information specified on the Webapp. Personal
              information is processed by us in accordance with our Privacy
              Policy.
              <br />
              <b className='mx-5 my-1'>2.3.</b>       Payment must be effected
              by credit card using the ordering facility on the Webapp. All
              payment is secured by STRIPE.
              <br />
              <b className='mx-5 my-1'>2.4.</b>       Confirmation of your order
              will be sent to your nominated email address once your payment for
              the transaction has been cleared.
              <br />
              <b className='mx-5 my-1'>2.5.</b>       You may not cancel an
              order once it has been submitted and paid, even if you have not
              received a confirmation email from CST.
              <h5 className='mx-5 my-1'>3. SECURITY POLICY</h5>
              <br />
              <b className='mx-5 my-1'>3.1.</b>       When purchasing from the
              Webapp your financial details are passed through a secure server.
              <br />
              <b className='mx-5 my-1'>3.2.</b>       No transmission over the
              Internet can be guaranteed as totally secure. Whilst CST strives
              to protect such information, it does not warrant and cannot ensure
              the security of any information which you transmit to it.
              Accordingly, any information which you transmit to CST, including
              your credit card details, is transmitted at your own risk and to
              the fullest extent permitted by law, CST shall have no liability
              to you for any financial or consequential loss or damage suffered
              by you in anyway whatsoever arising out of or related to your use
              of the Webapp, whether due to negligence, breach of contract,
              statute or statutory duty by CST.
              <br />
              <b className='mx-5 my-1'>3.3.</b>       Once CST receives your
              transmission, it will take reasonable steps to preserve the
              security of such information.
              <br />
              <b className='mx-5 my-1'>3.4.</b>       Some of the information
              submitted by you through the Website will contain personal
              information. Such personal information is only used for the
              purposes of delivering the service of renting the Smart Locker
              Facility.
              <h5 className='mx-5 my-1'>4. GENERAL</h5>
              <br />
              <b className='mx-5 my-1'>4.1</b>     Each of the terms of these
              Conditions of Sale shall be subject to and shall apply to the
              fullest extent permitted by law.
              <br />
              <b className='mx-5 my-1'>4.2</b>     Any provision stated herein
              which is held to be illegal, invalid or unenforceable will be
              ineffective solely to the extent of such illegality, invalidity or
              unenforceability, without affecting the other provisions herein
              which shall remain in full force and effect, and will be deemed to
              be amended as necessary to give effect as close as possible to the
              intent and purposes of the illegal, invalid or unenforceable
              provision.
              <br />
              <b className='mx-5 my-1'>4.3</b>     These Conditions of Sale
              shall be governed by and construed in accordance with the laws of
              Singapore and the courts of Singapore shall have exclusive
              jurisdiction over any dispute arising in connection with these
              Conditions of Sale.
            </>
          </div>
          <div className='abs'>
            <div className='d-flex my-3'>
              {/* <input type="checkbox" id="accept" name="accept" value="accept"/> */}
              <input
                type='checkbox'
                className='checkbox'
                onChange={getcheckbox}
                disabled={!bottom}
              />
              <div className={!bottom ? 'mx-1 my-2 text-gray' : 'mx-1 my-2'}>
                <p>I have read and accept the terms & conditions</p>
              </div>
            </div>
            <button
              className='btn-lockernew'
              disabled={!bottom}
              onClick={handleClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Terms;
