import React, { useEffect } from "react";
import "../assests/css/login.css";
import Button from "./Button";

import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Login = () => {
  const LoginHandler = async () => {
    var phone = document.getElementById("phone").value;
    var password = document.getElementById("password").value;
    if (phone.length === 0) {
      toast.error("Mobile Number is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (password.length === 0) {
      toast.error("Password is Required", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        phone: phone,
        password: password,
      };
      var loginuser = await axios
        .post(`${process.env.REACT_APP_SERVER}/users/login`, data)
        .then((res) => {
          console.log(res);
          return res.data;
        }).catch((e)=>{
          console.log(e);
          toast.error(e.response.data, {
            autoClose: 2000,
            transition: Slide,
          });
        })
        ;
      if (loginuser[0].id !== undefined) {
        toast.success('Success', {
          autoClose: 2000,
          transition: Slide,
        });
        var userid = loginuser[0].id;
        var datanew = await axios
          .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
          .then((res) => {
            return res.data;
          });
        var mylocker = await datanew.filter((datanew1) => {
          return Number(datanew1.user) === Number(userid);
        });
        if (mylocker.length !== 0) {
          sessionStorage.setItem("userid", loginuser[0].id);
          sessionStorage.setItem("local_useremail", loginuser[0].email);
          window.location.replace("/Open_locker");
        } else {
          toast.info("No Locker Available...", {
            autoClose: 2000,
            transition: Slide,
          });
          setTimeout(() => {
            window.location.replace("/")
          }, 2000);
        }
      } else {
        toast.error(loginuser, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  useEffect(() => {
    var userid = sessionStorage.getItem("userid");
    if (userid !== null) {
      window.location.replace("/locker");
    }
  }, []);
  const register_handler = () => {
    window.location.replace("/");
  };
  return (
    <>
      <div className="center">
        <div className="card-set-up card-height-100">
          <div className="text-center">
            <h3>Welcome to Skypark Sentosa Locker</h3>
          </div>
          <div className="Login_fields">
            <div className="Login_input">
              <label>Mobile Number</label>
              <input placeholder="Enter your Mobile Number" id="phone" />
            </div>
            <div className="Login_input">
              <label>Passcode</label>
              <input
                placeholder="Enter you Passcode"
                type="password"
                id="password"
              />
            </div>
          </div>
          <p style={{ cursor: "pointer" }} onClick={register_handler}>
            Don't have Account ? Sign up.
          </p>
          <Button name={"Sign in"} handleClick={LoginHandler} />
        </div>
      </div>
      <ToastContainer />
    </>
    // <div className="center">
    //   <div className="card-set-up card-height-100">

    //     <p onClick={register_handler}>Don't have Account ? Sign up.</p>
    //     <button onClick={LoginHandler} className="sign-btn-text">
    //       Sing in
    //     </button>
    //     <ToastContainer />
    //   </div>
    // </div>
  );
};

export default Login;
