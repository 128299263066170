import React from "react";
import {Outlet,Navigate } from "react-router-dom";

function ProtectedRouteUser({ component: Component, ...restOfProps }) {
  let auth1 = sessionStorage.getItem('userid')
    // const navigate = useNavigate();

    console.log(auth1);
    return (
      auth1? 
       <>
        <Outlet/>
       </>
      :<Navigate to='/'/>
    )
}

export default ProtectedRouteUser;