import React from "react";
import {Outlet,Navigate } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let auth = sessionStorage.getItem('local_userid')
    // const navigate = useNavigate();
    console.log(auth);
    return (
      auth?
      <> 
      <Outlet/> 
      </>  
      :<Navigate to='/'/>
    )
}

export default ProtectedRoute;