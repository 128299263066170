

import React,{useState} from "react";
// import Button from "./Button";
import { useNavigate } from "react-router-dom";
import '../assests/css/lockerAll.css'
import { IoWarningOutline } from 'react-icons/io5';
import axios from "axios";
function TestFile() {
    const [open, setopen] = useState(false)
    const [passcode, setpasscode] = useState('')
  const navigate = useNavigate();

  function handleClick() {
    navigate("/Checkuser");
  }
  function handleClick_1() {
    setopen(true)
//    alert('open all locker')
  }
  const handleClick_2 = async() => {
 
  if(passcode === null || passcode === undefined ||passcode === '' ){
    alert('Enter Passcode')
  }
  else {
    var  obj = {
      "email": sessionStorage.getItem('local_useremail'),
      "passcode": passcode
  }
    var data = await axios
    .post(`${process.env.REACT_APP_SERVER}/locker/unlockerall`,obj)
    .then((res) => {
      return res.data;
    });
    console.log(data);
    setopen(false)
  }

  };
  return (
    <>
      <div className="center">
        <div className="card-set-up card-height-100">
          <div className="text-center">
            <h3>Welcome to Skypark Sentosa Locker</h3>
          </div>
          <div className="my-4">
            <div className="my-3">
              <button className="btn-lockernew" onClick={handleClick}>
                Customer Locker
              </button>
            </div>
            <div className="my-3">
              <button className="btn-lockernew" onClick={handleClick_1}>
               All Locker
              </button>
            </div>
            {/* <div className="my-3">
              <button className="btn-lockernew" onClick={handleClick_2}>
                
              </button>
            </div> */}
          </div>
        </div>
        {open&&
        
        <>
        <div className="model-window-all">
        <div className="model-window-all-card">
        <IoWarningOutline/>
        {/* <h4>Alert !</h4> */}
        <p>Are you sure want to open all lockers</p>
        <input type='text' value={passcode} onChange={(e)=> setpasscode(e.target.value)}/>
        <div className="btn-con">
        <button onClick={()=>setopen(false)}>Cancel</button>
        <button onClick={handleClick_2}>Confirm</button>
        </div>

        </div>
        </div>
        
        </>
        
        }
      </div>
    </>
  );
}

export default TestFile;


//     "socket.io-client": "^4.5.3",
// "socket.io-react": "^1.2.0",