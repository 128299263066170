import React from 'react';
import { BiUser } from 'react-icons/bi';
import { BsPhone } from 'react-icons/bs';
import Button from '../Components/Button';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { toast, Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BiReset } from 'react-icons/bi';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../assests/css/Detail.css';
function Detail() {
  const [OTP, setOTP] = useState('');
  const [ispayment, setispayment] = useState(false);
  const [color, setColor] = useState('');
  const [userName, setuserName] = useState('');
  const [phoneNo, setPhone] = useState('');
  const [CodeLenth, setCodeLenth] = useState(0);

  function onChangeValue(event) {
    setColor(event.target.value);
  }
  const handlecheck = (e) => {
    setuserName(e.target.value.replace(/[^A-Za-z ]*$/gi, ''));
  };
  async function handleClick() {
    // console.log('phoneNo',phoneNo);
    var phone = phoneNo.slice(CodeLenth);
    var name = document.getElementById('name').value;
    // console.log('otp',OTP.length);
    // console.log(phone);
    // console.log(name);
    console.log({
      name: name,
      phoneNo: phone,
      otp: OTP,
      color: color,
    });
    if (name === 0 || name === '' || name === null || name === undefined) {
      toast.error('Name is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (
      phone.length === 0 ||
      phone === '' ||
      phone === null ||
      phone === undefined
    ) {
      toast.error('Mobile Number is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (
      OTP.length !== 4 ||
      OTP === '' ||
      OTP === null ||
      OTP === undefined
    ) {
      toast.error('Passcode is Required', {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (color.length === 0) {
      toast.error('Please Select a color', {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var data = {
        firstname: name,
        phone: phone,
        password: OTP,
        gender: color,
      };
      var checkuser = await axios
        .post(`${process.env.REACT_APP_SERVER}/users/viewbyphone`, {
          phone: phone,
        })
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data, {
            autoClose: 2000,
            transition: Slide,
          });
        });
      if (checkuser.length === 0) {
        var createuser = await axios
          .post(`${process.env.REACT_APP_SERVER}/users/create`, data)
          .then((res) => {
            console.log(res);
            return res.data;
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data, {
              autoClose: 2000,
              transition: Slide,
            });
          });
        if (createuser !== null && createuser !== undefined) {
          console.log(createuser);
          toast.success('User Created..', {
            autoClose: 2000,
            transition: Slide,
          });
          sessionStorage.setItem('userid', createuser.id);
          setispayment(true);
        }
      } else {
        var datanew = {
          firstname: name,
          phone: phone,
          password: OTP,
          gender: color,
          id: checkuser[0].id,
        };
        var updateuser = await axios
          .post(`${process.env.REACT_APP_SERVER}/users/update`, datanew)
          .then((res) => {
            return res.data;
          });
        if (updateuser === 'Updated Successfully') {
          toast.success('User Created..', {
            autoClose: 2000,
            transition: Slide,
          });
          sessionStorage.setItem('userid', checkuser[0].id);
          setispayment(true);
        }
      }
    }
  }

  const [product] = useState({
    name: 'Skypark Sentosa - Locker Booking',
    price: sessionStorage.getItem('price'),
    description: 'This is a Skypark Sentosa - Locker Booking',
  });

  async function handleToken(token, addresses) {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/checkout`,
      {
        token,
        product,
      }
    );
    console.log(response.data?.status);
    //"failure"
    if (response.data?.status !== 'failure') {
      if (Number(sessionStorage.getItem('slot')) === 1) {
        var data = await axios
          .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
          .then((res) => {
            return res.data;
          });
        var checkuser = await data.filter((datanew) => {
          return datanew.user === null;
        });
        if (checkuser.length !== 0) {
          var newdatas = checkuser.sort(() => Math.random() - 0.5);
          sessionStorage.setItem('locker', newdatas[0].locker_number);
          var newdata = {
            locker: newdatas[0].locker_number,
            date: moment().format('DD-MM-YYYY'),
            fromtime: moment().format('HH:mm'),
            totime: moment().add(60, 'minutes').format('HH:mm'),
            userid: sessionStorage.getItem('userid'),
            status: 'Occupied',
            price: sessionStorage.getItem('price'),
          };
          var cretebooking = await axios
            .post(
              `${process.env.REACT_APP_SERVER}/lockerbooking/create`,
              newdata
            )
            .then((res) => {
              return res.data;
            });
          if (cretebooking === 'Registration successful') {
            toast.success('Success! Check email for details', {
              autoClose: 2000,
              transition: Slide,
            });
          }
          setTimeout(() => {
            window.location.replace('/success');
          }, 2000);
        } else {
          toast.error('locker not available', {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var data1 = await axios
          .get(`${process.env.REACT_APP_SERVER}/locker/viewall`)
          .then((res) => {
            return res.data;
          });
        var checkuser1 = await data1.filter((datanew) => {
          return datanew.user === null;
        });
        if (checkuser1.length !== 0) {
          var newdatas1 = checkuser1.sort(() => Math.random() - 0.5);
          sessionStorage.setItem('locker', newdatas1[0].locker_number);

          var newdata1 = {
            locker: newdatas1[0].locker_number,
            date: moment().format('DD-MM-YYYY'),
            fromtime: moment().format('HH:mm'),
            totime: moment().add(120, 'minutes').format('HH:mm'),
            userid: sessionStorage.getItem('userid'),
            status: 'Occupied',
            price: sessionStorage.getItem('price'),
          };
          var cretebooking1 = await axios
            .post(
              `${process.env.REACT_APP_SERVER}/lockerbooking/create`,
              newdata1
            )
            .then((res) => {
              return res.data;
            });
          if (cretebooking1 === 'Registration successful') {
            toast.success('Success! Check email for details', {
              autoClose: 2000,
              transition: Slide,
            });
          }
          setTimeout(() => {
            window.location.replace('/success');
          }, 2000);
        } else {
          toast.error('locker not available', {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    } else {
      toast.error(response.data?.error1, {
        autoClose: 2000,
        transition: Slide,
      });
    }
  }
  const resetbtn = async () => {
    document.getElementById('name').value = '';
    document.getElementById('phone').value = '';
    setOTP('');
    setColor('');
  };
  return (
    <>
      <div className='center my-2'>
        <div className='card-set-up card-height-350'>
          <div className='text-center'>
            <h3 className='bold'>
              {ispayment === true
                ? 'User Account Created'
                : 'Enter the details'}

              <span
                style={{
                  cursor: 'pointer',
                  marginTop: '-6px',
                  marginLeft: '30px',
                  position: 'absolute',
                  fontSize: '30px',
                }}
                onClick={resetbtn}
              >
                <BiReset />
              </span>
            </h3>
          </div>

          <div className='justify-center'>
            <div className='my-2 wrapper'>
              <div className='icon-input center'>
                <BiUser />
              </div>
              {ispayment === true ? (
                <input
                  type='text'
                  name='name'
                  id='name'
                  className='input-detail input-name'
                  placeholder='Name'
                  required
                  disabled
                  pattern='[A-Za-z]{15}'
                />
              ) : (
                <input
                  type='text'
                  name='name'
                  id='name'
                  className='input-detail input-name'
                  placeholder='Name'
                  required
                  pattern='[A-Za-z]{15}'
                  onChange={(e) => handlecheck(e)}
                  value={userName}
                />
              )}
            </div>

            <div className='my-1 wrapper'>
              {/* <div className="icon-input center">
                <BsPhone />
              </div> */}
              {ispayment === true ? (
                // <input
                //   type="tel"
                //   id="phone"
                //   name="phone"
                //   className="input-detail input-name"
                //   placeholder="Mobile Number"
                //   required
                //   disabled
                // ></input>
                <PhoneInput
                  country={'sg'}
                  value={phoneNo}
                  disabled={true}
                  countryCodeEditable={false}
                  copyNumbersOnly={true}
                  enableSearch={true}
                  onChange={(value, data, event, formattedValue) => {
                    setCodeLenth(data.dialCode.length);
                    setPhone(value);
                  }}
                />
              ) : (
                <PhoneInput
                  country={'sg'}
                  value={phoneNo}
                  // onChange={phone => setPhone(phone )}
                  // disableCountryCode={true}
                  countryCodeEditable={false}
                  copyNumbersOnly={true}
                  enableSearch={true}
                  onChange={(value, data, event, formattedValue) => {
                    setCodeLenth(data.dialCode.length);
                    setPhone(value);
                  }}
                />
                // <input
                //   type="tel"
                //   id="phone"
                //   name="phone"
                //   className="input-detail input-name"
                //   placeholder="Mobile Number"
                //   required
                // ></input>
              )}
            </div>

            <div className='text-center'>
              <h3 className='bold my-2'>Enter Passcode (0 to 9 only)</h3>
              <div className='mx-2'>
                {ispayment === true ? (
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType='number'
                    disabled={true}
                    secure
                  />
                ) : (
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType='number'
                    disabled={false}
                    secure
                  />
                )}
              </div>
            </div>

            <div className='text-center'>
              <h3 className='bold my-2'>Select a color</h3>
              {/* <input type="password" name="password" className='input-detail'></input> */}
              <div
                className='color-select my-1'
                onChange={onChangeValue}
                required
              >
                <>
                  <input
                    type='radio'
                    className={color === 'red' ? 'red selected' : 'red'}
                    id='red'
                    value='red'
                    name='red'
                    checked={color === 'red'}
                  ></input>
                  <input
                    type='radio'
                    className={color === 'green' ? 'green selected' : 'green'}
                    id='green'
                    value='green'
                    name='green'
                    checked={color === 'green'}
                  ></input>
                  <input
                    type='radio'
                    className={color === 'blue' ? 'blue selected' : 'blue'}
                    id='blue'
                    value='blue'
                    name='blue'
                    checked={color === 'blue'}
                  ></input>
                </>
                {/* )} */}
              </div>
            </div>

            <div className='my-2'>
              {!ispayment && (
                <Button name={'Submit'} handleClick={handleClick} />
              )}
              <center>
                {ispayment && (
                  <StripeCheckout
                    label='Proceed to Payment'
                    stripeKey='pk_live_51LTLF0JDcsjJGlFu9Pkf8CVVttvwXKPcLVxvP7rrxVDmyCdRaQxxrEkjBSXUZF1nBgZ1gcXmpjKSdhJkGTytA8AI00Ptt9nNVP'
                    token={handleToken}
                    amount={product.price * 100}
                    name='Booking-Skypark Sentosa'
                    billingAddress
                    className='payment'
                    shippingAddress
                    currency='SGD'
                  />
                )}
              </center>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Detail;
