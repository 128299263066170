import React from 'react';
import oops from "../assests/jump1.png";
import Button from './Button';
import { useNavigate } from "react-router-dom"

function Notfound() {
    const navigate = useNavigate();
  return (
    <>
    <div className='center'>
        <div className='card-set-up card-height-500' onClick={()=>{
            navigate("/")
        }}>
            <div className='text-center'>
                <h1 clas>OOPS</h1>
            </div>
            <div className='opp-img-head'>
                <img src={oops} alt="404" className='img-fluid'/>
            </div>
<div className='text-center'><h6>Sorry , we are not able to <br/>find the page you are looking for</h6></div>
        </div>
       
    </div>
        </>
  )
}

export default Notfound